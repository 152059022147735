import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Money Transfer App Template | Uizard"
    canonical="/templates/mobile-app-templates/money-transfer-mobile-app/"
    category="Tablet Templates"
    url="/templates/tablet-templates/money-transfer-tablet-app/"
    metaDescription="Introducing MONEYPAL, Uizard's smart and intuitive money transfer tablet app design template. Customize and create your own design now."
    description="
    h2:Introducing MONEYPAL, our money transfer app design template for tablet
    <br/>
    Have we piqued your interest? MONEYPAL, our money transfer app <a:https://uizard.io/templates/tablet-templates/>design template for tablet</a> is here, and it comes with everything you could possibly need for your own design project. Perfect for a solo project or for working as part of a team, MONEYPAL's must-have features and appealing aesthetic is sure to impress.
    <br/>
    h3:Transfer cash fast, design your app faster
    <br/>
    Don't burn through all your cash in a lengthy design and iteration process, use our premade MONEYPAL template to build your app design quicker and faster than ever before. Uizard is all about speed, and our money transfer app design for tablet devices is by no means an exception. Whether you are <a:https://uizard.io/wireframing/>UI wireframing</a> or creating a workable prototype, we've got you covered.
    <br/>
    h3:Customization like never before
    <br/>
    Use the template as it comes or add your own flavor to the design. With endless customization choices in Uizard's user friendly interface, you really can add your own twist to MONEYPAL. Simply open the template and get to work.
    "
    pages={[
      "A simple and clean secure login screen with PIN code interface example",
      "A personal account overview page demonstrating how your prospective users would interact with the app",
      "Money transfer flow to demonstrate how the app design actually functions ",
      "A clear and concise contact list screen mockup",
    ]}
    projectCode="MLBOeGAxKwh08EGWYvqW"
    img1={data.image1.childImageSharp}
    img1alt="money transfer tab app design template cover"
    img2={data.image2.childImageSharp}
    img2alt="money transfer tab app design template send money screen"
    img3={data.image3.childImageSharp}
    img3alt="money transfer tab app design template contacts screen"
    img4={data.image4.childImageSharp}
    img4alt="money transfer tab app design template account screen"
    img5={data.image5.childImageSharp}
    img5alt="money transfer tab app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/money-transfer-tablet-app/money-transfer-tab-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/money-transfer-tablet-app/money-transfer-tab-app-send.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/money-transfer-tablet-app/money-transfer-tab-app-contacts.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/money-transfer-tablet-app/money-transfer-tab-app-account.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/money-transfer-tablet-app/money-transfer-tab-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
